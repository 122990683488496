<template>
  <div id="header" class="header">
    <ul>
      <a class="logo" href="https://www.htu.edu.cn">
          <img
              style="
             height: 50px;
              border-radius: 4px;
              margin-top: 0px;
            "
              src="../assets/hnlogo.png"
              alt=""
          />
      </a>
      <a href="">
        <li style="margin-left:250px">
          <a>河南师范大学微表情实验平台</a>
          <span />
        </li>
      </a>
      <a>
        <li style="float:right;">
          <a href="http://wbqadmin.redclass.cn/">数据管理平台</a>
          <span />
        </li>
      </a>
    </ul>
  </div>
</template>

<script>
export default {
  name:'Head',
  data(){
    return{
      //后台管理登录平台的url
      url:'http://hsd-wbq.marchsoft.club'
    }
  }
}
</script>

<style  scoped>
.header {
  transition: all 0.2s linear;
  display: block;
  /* width: 1250px; */
  width: 100%;
  height: 50px;
  /* margin: 0px auto; */
  background-color: #0E3264;
  position: relative;
  font-size: 16px;
}

.header ul {
  display: block;
  width: 900px;
  position: relative;
  height: 50px;
  /*border-bottom: black 1px ridge;*/
  margin: 0 auto;
}

.header ul li a {
  position: relative;
  z-index: 5;
  color:white;
}
.header ul li span {
  width: 130px;
  height: 50px;
  position: absolute;
  left: -36px;
  top: 0px;
  z-index: 1;
}

.header ul li:hover a::after {
  transform: scale(1, 1);
  opacity: 1;
}

.header ul li a::after {
  display: block;
  content: "";
  height: 4px;
  width: 100%;
  position: relative;
  bottom: 10px;
  left: 0px;
  background: #194ac3;
  opacity: 0;
  transition: transform 0.2s ease-out, opacity 0.2s ease-out;
  transform-origin: 50% 0%;
  transform: scaleX(0);
}

@media screen and (max-width: 1250px) {
  .header {
    width: 100%;
  }
}
@media screen and (max-width: 800px) {
  .header li {
    margin-left: 30px;
  }
}
li a {
  text-decoration: none;
  color: black;
  line-height:50px;
}

.logo {
  display: block;
  height: 22px;
  width: 168px;
  position: absolute;
  text-align: center;
}
ul {
  display: block;
  height: 50px;
  text-align: center;
  padding: 0;
}

li {
  display: inline-block;
  height: 50px;
  margin-left: 72px;
  position: relative;
}
</style>
