<template>
  <div class="hello">
    <Head/>
    <!-- <div style="text-align: right">
        <a style="margin-right: 100px;margin-top: 10px;display: inline-block;" href="/guige.html">规格型号</a>
    </div> -->
    <div class="content">
      <div class="camera">
        <video id="video" width="500" height="500" style="display:inline-block;" />
        <canvas id="canvas" style="display:none" width="320" height="320" />
      </div>
      <!--     <div id="photo"></div>-->
      <!--     <h4>获取的base64位PNG图像</h4>-->
      <!-- <textarea id="text"></textarea> -->
      <div class="data">
        <div v-if="smile != max">微笑🙂
          <div class="progress">
            <div class="progress-bar progress-bar-striped active" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100" :style="{width:smile+'%'}" role="progressbar" >
              {{ smile }}
            </div>
          </div>
        </div>
        <div v-else style="color:red">微笑🙂
          <div class="progress">
            <div class="progress-bar progress-bar-striped active" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100" :style="{width:smile+'%'}" role="progressbar" >
              {{ smile }}
            </div>
          </div>
        </div>

        <div v-if="emotion.anger != max">愤怒🤬
          <div class="progress">
            <div class="progress-bar progress-bar-striped active" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100" :style="{width:emotion.anger+'%'}" role="progressbar" >
              {{ emotion.anger }}
            </div>
          </div>
        </div>
        <div v-else style="color:red">愤怒🤬
          <div class="progress">
            <div class="progress-bar progress-bar-striped active" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100" :style="{width:emotion.anger+'%'}" role="progressbar" >
              {{ emotion.anger }}
            </div>
          </div>
        </div>

        <div v-if="emotion.disgust != max">厌恶🤢
          <div class="progress">
            <div class="progress-bar progress-bar-striped active" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100" :style="{width:emotion.disgust+'%'}" role="progressbar" >
              {{ emotion.disgust }}
            </div>
          </div>
        </div>
        <div v-else style="color:red">厌恶🤢
          <div class="progress">
            <div class="progress-bar progress-bar-striped active" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100" :style="{width:emotion.disgust+'%'}" role="progressbar" >
              {{ emotion.disgust }}
            </div>
          </div>
        </div>

        <div v-if="emotion.fear != max">恐惧😱
          <div class="progress">
            <div class="progress-bar progress-bar-striped active" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100" :style="{width:emotion.fear+'%'}" role="progressbar" >
              {{ emotion.fear }}
            </div>
          </div>
        </div>
        <div v-else style="color:red">恐惧😱
          <div class="progress">
            <div class="progress-bar progress-bar-striped active" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100" :style="{width:emotion.fear+'%'}" role="progressbar" >
              {{ emotion.fear }}
            </div>
          </div>
        </div>

        <div v-if="emotion.happiness != max">幸福😊
          <div class="progress">
            <div class="progress-bar progress-bar-striped active" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100" :style="{width:emotion.happiness+'%'}" role="progressbar" >
              {{ emotion.happiness }}
            </div>
          </div>
        </div>
        <div v-else style="color:red">幸福😊
          <div class="progress">
            <div class="progress-bar progress-bar-striped active" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100" :style="{width:emotion.happiness+'%'}" role="progressbar" >
              {{ emotion.happiness }}
            </div>
          </div>
        </div>

        <div v-if="emotion.neutral != max">空档😐
          <div class="progress">
            <div class="progress-bar progress-bar-striped active" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100" :style="{width:emotion.neutral+'%'}" role="progressbar" >
              {{ emotion.neutral }}
            </div>
          </div>
        </div>
        <div v-else style="color:red">空档😐
          <div class="progress">
            <div class="progress-bar progress-bar-striped active" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100" :style="{width:emotion.neutral+'%'}" role="progressbar" >
              {{ emotion.neutral }}
            </div>
          </div>
        </div>
        <div v-if="emotion.sadness != max">悲伤😟
          <div class="progress">
            <div class="progress-bar progress-bar-striped active" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100" :style="{width:emotion.sadness+'%'}" role="progressbar" >
              {{ emotion.sadness }}
            </div>
          </div>
        </div>
        <div v-else style="color:red">悲伤😟
          <div class="progress">
            <div class="progress-bar progress-bar-striped active" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100" :style="{width:emotion.sadness+'%'}" role="progressbar" >
              {{ emotion.sadness }}
            </div>
          </div>
        </div>

        <div v-if="emotion.surprise != max">惊喜😮
          <div class="progress">
            <div class="progress-bar progress-bar-striped active" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100" :style="{width:emotion.surprise+'%'}" role="progressbar" >
              {{ emotion.surprise }}
            </div>
          </div>
        </div>
        <div v-else style="color:red">惊喜😮
          <div class="progress">
            <div class="progress-bar progress-bar-striped active" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100" :style="{width:emotion.surprise+'%'}" role="progressbar" >
              {{ emotion.surprise }}
            </div>
          </div>
        </div>
      </div>
    </div>

<!--    <div style="width: 200px;vertical-align: top;font-size: 14px;margin: 0 auto;text-align: left;display:inline-block;margin-left: 50px;">-->
<!--      {{ all }}-->
<!--    </div>-->
  </div>
</template>

<script>
import axios from 'axios'
import Head from '../../components/NavigationBar'
export default {
  name: 'HelloWorld',
  // eslint-disable-next-line vue/no-unused-components
  components: { Head },
  props: {
  },
  data() {
    return {
      max: -1,
      emotion: {
        anger: 0,
        disgust: 0,
        fear: 0,
        happiness: 0,
        neutral: 0,
        sadness: 0,
        surprise: 0
      },
      smile: 0,
      all: {},
      student: {
        college: '',
        major: '',
        classes: '',
        name: '',
        studentId: '',
        gender: '',
        anger: 0,
        disgust: 0,
        fear: 0,
        happiness: 0,
        neutral: 0,
        sadness: 0,
        surprise: 0,
        data: {}
      },
      url:'/api/expression-front/add',
      dynamicWidth:{
        width:0
      }
    }
  },
  mounted() {
    // 判断浏览器是否支持HTML5 Canvas
    window.onload = function() {
      try {
      // 动态创建一个canvas元 ，并获取他2Dcontext。如果出现异常则表示不支持 document.createElement("canvas").getContext("2d");
        takePhotos()
      } catch (e) {
        document.getElementById('support').innerHTML = '浏览器不支持HTML5 CANVAS，建议使用谷歌浏览器'
      }
    }
    const that = this
    // 拍照主函数
    function takePhotos() {
      // 这段代 主要是获取摄像头的视频流并显示在Video 签中
      var canvas = document.getElementById('canvas')
      var context = canvas.getContext('2d')
      var video = document.getElementById('video')

      function successCallback(stream) {
        // Set the source of the video element with the stream from the camera
        if (video.mozSrcObject !== undefined) {
          video.mozSrcObject = stream
        } else {
          video.srcObject = stream
        }
        video.play()
      }
      function errorCallback(error) {
        alert('错误代码: [CODE ' + error.code + ']')
        // Display a friendly "sorry" message to the user
      }

      navigator.getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia
      window.URL = window.URL || window.webkitURL || window.mozURL || window.msURL

      // Call the getUserMedia method with our callback functions
      if (navigator.getUserMedia) {
        navigator.getUserMedia({ video: true }, successCallback, errorCallback)
      } else {
        alert('浏览器不支持getUserMedia.建议使用谷歌浏览器,或者手机在浏览器打开')
        // Display a friendly "sorry" message to the user
      }

      // 这个是拍照按钮的事件，
      // document.getElementById("snap").onclick
      setInterval(() => {
        context.drawImage(video, 0, 0, 320, 320)
        // 获取浏览器页面的画布对象
        var canvans = document.getElementById('canvas')
        // 以下开始编 数据
        var imgData = canvans.toDataURL()
        // 将图像转换为base64数据
        var base64Data = imgData.substr(22)
        // 将获得的base64数据设置为photo的背景图
        // document.getElementById("photo").style.backgroundImage="url(data:image/png;base64,"+base64Data+")";
        // document.getElementById("text").innerHTML=base64Data;
        axios.post('https://hsdapi.loocall.com/face_detect', {
          image_base64_code: 'data:image/png;base64,' + base64Data,
          api_key: 'hsd-5185d641-ccd3-4894-9d6b-b63f35bb5dfd'
        }).then((res) => {
          // console.log(res.data)
          if (res.data.retcode === 0 && res.data.data.face_num === 1) {
            // that.emotion = {};
            that.emotion = res.data.data.faces[0].attributes.emotion
            that.smile = res.data.data.faces[0].attributes.smile.value
            that.all = res.data
            that.max = Math.max(that.emotion.anger,
              that.emotion.disgust,
              that.emotion.fear,
              that.emotion.happiness,
              that.emotion.neutral,
              that.emotion.sadness,
              that.emotion.surprise, that.smile)
              that.addExpressionData();
            that.dynamicWidth.width=that.smile+"%"
          } else {
            that.emotion = {
              anger: 0,
              disgust: 0,
              fear: 0,
              happiness: 0,
              neutral: 0,
              sadness: 0,
              surprise: 0
            }
          }
        })
      }, 1000)
    }

  },
  methods: {
    addExpressionData() {
      var dataObject = {
        grade:"",
        college:"",
        major: "",
        classes:"",
        name: "",
        studentId: this.getUrlParam("param"),
        gender: 0,
        smile:this.smile,
        anger: this.emotion.anger,
        detest: this.emotion.disgust,
        dread: this.emotion.fear,
        felicity: this.emotion.happiness,
        blank: this.emotion.neutral,
        sad: this.emotion.sadness,
        surprise: this.emotion.surprise,
        data: JSON.stringify(this.all)
      }
      axios.post(this.url,dataObject).then((res)=>{
        if(res.data.message==="SUCCESS"){
          console.log("成功添加数据",res.data.message)
        }else{
          console.log("添加数据失败",res.data.message)
        }

      })
    },
    getUrlParam(name){
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); //构造一个含有目标参数的正则表达式对象
      var r = window.location.search.substr(1).match(reg); //匹配目标参数
      if (r != null) return unescape(r[2]); return null; //返回参数值
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
*{  padding:0; margin:0; }
#video{ background:#000; display:block; margin: 0px auto; }
.hello{
  margin:0;
  padding:0px;
}
.content{
  width:900px;
  height:600px;
  margin: 20px auto;
  /*background-color: #194ac3;*/
}
.camera{
  display: inline;
  float: left;
  margin-top:40px;
}
.data{
  width: 200px;
  line-height: 40px;
  vertical-align: top;
  font-weight: 700;
  font-size: 18px;
  text-align: left;
  display:inline-block;
  float: right;
  margin-right: 0px;
  margin-top:0px;
}
#snap{ width:100px; height:40px; line-height:40px; margin:10px 0;     }
#photo{ width:320px; height:320px;  background-repeat:no-repeat; background:#000 }
#text{ width:320px; height:200px;  }

@keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }

  to {
    background-position: 0 0;
  }
}
.progress {
  height: 22px;
  margin-bottom: 11px;
  overflow: hidden;
  background-color: #e4eaec;
  border-radius: 3px;
  -webkit-box-shadow: inset 0 1px 2px rgba(0,0,0,.1);
  box-shadow: inset 0 1px 2px rgba(0,0,0,.1);
}
.progress-bar {
  float: left;
  width: 0;
  height: 100%;
  font-size: 12px;
  line-height: 22px;
  color: #fff;
  text-align: center;
  background-color: #62a8ea;
  -webkit-box-shadow: inset 0 -1px 0 rgba(0,0,0,.15);
  box-shadow: inset 0 -1px 0 rgba(0,0,0,.15);
  -webkit-transition: width .6s ease;
  -o-transition: width .6s ease;
  transition: width .6s ease;
}
.progress-bar-striped, .progress-striped .progress-bar {
  background-image: -webkit-linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
  background-image: -o-linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
  background-image: linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
  -webkit-background-size: 40px 40px;
  background-size: 40px 40px;
}
.progress-bar.active, .progress.active .progress-bar {
  -webkit-animation: progress-bar-stripes 2s linear infinite;
  -o-animation: progress-bar-stripes 2s linear infinite;
  animation: progress-bar-stripes 2s linear infinite;
}
</style>
